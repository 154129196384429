export default function formatTime(date) {

    if (!date) return ""

    date = new Date(date);

    let current = new Date();
    let currentTS = (current.getTime() / 1000) | 0;

    let difference = currentTS - ((date.getTime() / 1000) | 0);

    if (difference < 2) return "Gerade eben";

    if (difference < 60) {
        return "vor " + difference + " Sekunden";
    }

    if (difference < 120) {
        return "vor einer Minute";
    }

    if (difference < 3600) {
        return "vor " + ((difference / 60) | 0) + " Minuten";
    }

    if (difference < 7200) {
        return "vor einer Stunde";
    }

    if (difference < 86400) {
        return "vor " + ((difference / 3600) | 0) + " Stunden";
    }

    if (difference < 86400 * 2) {
        return "vor 1 Tag";
    }

    if (difference < 86400 * 7) {
        return "vor " + ((difference / 86400) | 0) + " Tagen";
    }

    if (difference < 604800 * 2) {
        return "vor " + ((difference / 604800) | 0) + " Woche";
    }

    if (difference < 604800 * 7) {
        return "vor " + ((difference / 604800) | 0) + " Wochen";
    }

    return (
        "" +
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
        "." +
        (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) +
        "." +
        date.getFullYear()
    );
}
