import React from "react"
import TileMenuList from "../../components/menus/Tile/TileMenuList/TileMenuList"
import ApplePage from "../../components/pages/ApplePage/ApplePage"
import "./MapPage.scss"


function MapPage({ }) {
    return (
        <ApplePage
            title={"Umgebung"}
            className="MapPage"
            // optionsText="Anpassen"
            // onOptionClick={() => {

            // }}
            hideNavigateBack
            subComponent={
                <div className="col-12 p-1">
                    <input className="col-12" placeholder="Suchen"></input>
                </div>
            }
        >
            <div className="mt-4">
                <TileMenuList menus={menus} />
            </div>
            {/* <LiveMap children={} /> */}
        </ApplePage>
    )
}
export default MapPage

const menus = [
    {
        "title": "Übersicht",
        "type": "TILES",
        "tileTypes": "MODERN",
        "tiles": [
            {
                "title": "Gastronomie",
                "icon": "burger-soda",
                "page": {
                    "uri": "/list/",
                    "payload": {
                        "keywordFilter": "Gastronomie",
                    }
                },
                background: "#e69812",
            },
            {
                "title": "Shopping",
                "icon": "bags-shopping",
                "page": {
                    "uri": "/list/",
                    "payload": {
                        "keywordFilter": ["Einkaufen", "Lebensmittel"],
                    }
                },
                background: "#de1032",
            },
            {
                "title": "Freizeit",
                "icon": "calendar-day",
                "page": {
                    "uri": "EventListPage",
                    "payload": null
                },
                background: "linear-gradient(174deg,  rgba(253,29,29,1) 20%, rgba(252,176,69,1) 130%)"
            },

            {
                "title": "Dienstleister",
                "icon": "tools",
                "page": {
                    "uri": "/list/",
                },
                background: "#955bbd",
            },

            {
                "title": "Gesundheit",
                "icon": "heartbeat",
                "page": {
                    "uri": "/list/",
                },
                background: "#78e08f",
            },


            {
                "title": "Institutionen",
                "icon": "building",
                "page": {
                    "uri": "/list/",
                },
                background: "#281791",
            },


        ]
    },

    {
        title: "Mobilität",
        type: "TILES",
        tileTypes: "MODERN",
        tiles: [

            {
                "title": "Tankstellen",
                "icon": "gas-pump",
                "page": {
                    "uri": "/list/",
                },
                background: "#3c6382",
            },

            {
                "title": "Regenradar",
                "icon": "cloud-sun-rain",
                "page": {
                    "uri": "/list/",
                },
                background: "linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(64,45,253,1) 100%)",
            },

            {
                "title": "Fahrplan",
                label: "Jetzt abstimmen",
                "icon": "bus",
                "page": {
                    "uri": "/list/",
                },
                background: "#eb2f06",
            },
        ]
    },



    {
        title: "Spezielle Anliegen",
        type: "TILES",
        tileTypes: "MODERN",
        tiles: [{
            "title": "Jobs",
            "icon": "user-astronaut",
            "page": {
                "uri": "/jobs/",
                "payload": null
            },
            background: "#fa983a"
        },
        {
            "title": "Immobilien",
            "icon": "house",
            "page": {
                "uri": "/property/",
                // "payload": { url: "https://www.bleifuss-immobilien.de/immobilien", openExternal: true }
            },
            background: "#60a3bc",
        },
        ]
    },

    {
        title: "Sonstiges",
        type: "TILES",
        tileTypes: "MODERN",
        tiles: [

            {
                "title": "Firmen",
                "icon": "building",
                "page": {
                    "uri": "/list/",
                },
                background: "#3c6382"
            },
            {
                "title": "Vereine",
                "icon": "users-class",
                "page": {
                    "uri": "/list/",
                },
                background: "#b71540"
            },

            // {
            //     "title": "Instagram",
            //     "icon": "instagram",
            //     "page": {
            //         "url": "/list/",
            //     },
            //     background: "#281791"
            // },

            {
                "title": <span className="dd">dash<span>design</span>;</span>,
                "icon": null,
                "page": {
                    "uri": "/dashdesign",
                },
                background: "#fff"
            },
        ]
    },
]