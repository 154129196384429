import React from "react"
import { Link } from "react-router-dom"
import ApplePage from "../../components/pages/ApplePage/ApplePage"
import "./JobsPage.scss"

function JobsPage({ }) {
    return (
        <ApplePage title={"Stellenangebote"} className="JobsPage">
            <h3>Diese Seite wird überarbeitet.</h3>
            <Link to="/home">
                <button>Zurück</button>
            </Link>
        </ApplePage>
    )
}
export default JobsPage