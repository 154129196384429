import React, { useState } from "react"
import "./FilterBar.scss"

function FilterBar({ className, filters, onFilterClick = () => null }) {

    const [Selection, setSelection] = useState([])

    function handleFilterClick(filter) {
        onFilterClick(filter)
        setSelection([...Selection, filter])

        console.log(filter)
    }

    function resetFilter() {
        setSelection([])
        onFilterClick(null)
    }

    return (
        <div className={"FilterBar " + className}>
            {Selection.length > 0 ?
                <div>
                    <RemoveFilter onClick={resetFilter} />
                    {Selection.map((i, k) => <Filter {...i} key={k} onClick={resetFilter} isActive />)}
                </div>
                :
                filters.map((i, k) => <Filter {...i} key={k} onClick={handleFilterClick} />)
            }
        </div>
    )
}
export default FilterBar

function Filter(filterData) {
    let { title, onClick, isActive, className = "Filter" } = filterData

    if (isActive) className += " isActive"

    return <button className={className} onClick={() => onClick(filterData)}>
        {title}
    </button >
}

function RemoveFilter(props) {
    return <button {...props} className="Filter RemoveFilter">
        <i className="fa-regular fa-solid fa-xmark"></i>
    </button>
}