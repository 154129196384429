import React from "react"
import "./ServerErrorPage.scss"

function ServerErrorPage({ }) {
    return (
        <div className="ServerErrorPage">

            <i className="fa-solid fa-triangle-exclamation"></i>
            <h1>Serverfehler!</h1>
            <p>Bitte versuche es gleich nochmal.</p>

        </div>
    )
}
export default ServerErrorPage