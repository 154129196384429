import React from "react"
import ApplePage from "../../components/pages/ApplePage/ApplePage"
import "./PropertyPage.scss"

function PropertyPage({ }) {
    return (
        <ApplePage title={"Immobilien"} className="PropertyPage">

        </ApplePage>
    )
}
export default PropertyPage