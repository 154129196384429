import React from "react"
import ServerErrorPage from "../../../container/errors/ServerErrorPage/ServerErrorPage"
import { useApi } from "../../../service/useApi"

function ListRender({ endpoint, render, loader = <p>loader...</p>, empty = <p>empty...</p>, error = <ServerErrorPage />, className }) {
    const { Data, Loading, Error } = useApi(endpoint)

    if (Error) return error
    if (Loading) return loader
    if (Data) {
        let value = Data

        if (Array.isArray(Data) && Data.length > 0) {
            value = Data
            let result = []

            value.forEach((element, k) => {
                result.push(render({ key: k, ...(element.attributes || element) }))
            });

            return <div className={className}>{result}</div>
        } else {
            return empty
        }
    }
}
export default ListRender