//Main API
export const OLD_API = "https://v5.api.diestadt.app"


// export const NEWS_ENDPOINT = API + "/posts"
// export const ENTRY_LIST_ENDPOINT = API + "/entries/list"

export class Strapi {
    //Read-only: app.diestadt.app
    static API_TOKEN = "9b49609812d56b2f206dacac11d386e083a97673a880510779b8000d2bc8e9316e600ef26255cbacabc33cbaa563218edcf29d4d4f207e12d723689516806098d0356e62f7740fd08c14783900cda86f9b2077d4e398679b892b7e2c3e5b5ccbe6d2e16393f16a37ac12a331a60ebf609068f6b8dc358e95b4756c5aafc4d9ce"
    static MAIN_ADDRESS = "https://strapi.diestadt.app"
    static API_ADDRESS = this.MAIN_ADDRESS + "/api"
}

//Strapi
// export const STRAPI_ROOT_ADDRESS = "http://localhost:1337"
export const STRAPI_ROOT_ADDRESS = Strapi.MAIN_ADDRESS
export const STRAPI_API_ADDRESS = Strapi.API_ADDRESS
// export const STRAPI_API_KEY = "f646648c36cdedc27f57db1e2744ef824812fceb375e2bd58a61ddc70671a67364c9998cb3c1c2b4290c19b5475a85fb041d3aff45a30ab32abddafc70f7afa2fa076355b8f2b1873dcf174ebb18cbfcc7d32dae373e629d2ebe367d8c1c451b1aa9f8dc92076dedefa307084af649cfa8975847752d99d3e37e3d9a0c4a1512"
export const STRAPI_API_KEY = Strapi.API_TOKEN
