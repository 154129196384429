import Cookies from "js-cookie"
import React from "react"
import { NavLink, Route, Routes } from "react-router-dom"
import "./BottomNavigation.scss"

function BottomNavigationItem({ icon, text, uri }) {
    let marginBottom = parseInt(Cookies.get("marginBottom") || "12")
    return (
        <NavLink to={uri} className="BottomNavigationItem" style={{ paddingBottom: marginBottom }}>
            {icon}
            <small>{text}</small>
        </NavLink>
    )
}
export default BottomNavigation

function BottomNavigation({ homeRoutes }) {
    let homeRoutesPaths = []
    homeRoutes.forEach((r) => {
        homeRoutesPaths.push(r.path)
    })
    return (
        <Routes>
            {renderMultiRoutes({
                paths: homeRoutesPaths,
                element: <div className="BottomNavigation">
                    {items.map((i, k) => <BottomNavigationItem {...i} key={k} />)}
                </div>,
            })}
        </Routes>
    )
}
const renderMultiRoutes = ({ element: Element, paths, ...rest }: MultiRoutes) =>
    paths.map((path) => <Route key={path} path={path} {...rest} element={Element} />);

const items = [
    {
        icon: <i className="fa-light fa-newspaper"></i>,
        text: "News",
        uri: "/news",
    },
    {
        icon: <i className="fa-light fa-calendar"></i>,
        text: "Ereignisse",
        uri: "/events",
    },
    {
        icon: <i className="fa-light fa-map-pin"></i>,
        text: "Umgebung",
        uri: "/map",
    },
    {
        icon: <i className="fa-light fa-house"></i>,
        text: "Grünberg",
        uri: "/home",
    },
]