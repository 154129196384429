import React, { useState } from "react"
import "./ImageList.scss"

function ImageList({ images }) {
    console.log(images)

    images.sort((a) => a.imageOrder)

    return (
        <div className="ImageListContainer">

            <div className="ImageList">
                {images.map((i, k) =>
                    <ImageItem {...i} key={k} />
                )}
            </div>
        </div>
    )
}
export default ImageList


function ImageItem({ url, }) {

    const [IsFullScreen, setIsFullScreen] = useState(false)

    const handleOpen = () => setIsFullScreen(!IsFullScreen)

    let popupCssClass = "ImageItem " + IsFullScreen

    return (
        <div className="col-12 col-lg-4 col-xl-3" >
            <div className={popupCssClass}>
                <button onClick={handleOpen}>
                    <img className="w-100" src={url} />
                </button>
            </div>
        </div>
    )
}