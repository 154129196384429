import React from "react"
import { Link } from "react-router-dom"
import StrapiImage from "../../api/strapi/StrapiImage/StrapiImage"
import BorderRadiusBox from "../../defaults/boxes/BorderRadiusBox/BorderRadiusBox"
import "./EventItem.scss"

function EventItem({ eventItem, className }) {

    let {
        title,
        ID,
        creator,
        priceText,
        bookingUrl,
        moreInfoUrl,
        media,
        location,
        startTime,
        startDate,
        endTime,
        endDate,
        slug,
        titleImage,
        titleImageSrc,
    } = eventItem

    let startTimestamp = createDateObject(startDate, startTime)
    let endTimestamp = createDateObject(endDate, endTime)
    // const averageColor = averageImageColor(titleImgSrc);
    // Baue eine JS Funktion, die eine Date Objekt in Text für Menschen umbaut. Wenn das Datum am selben Tag ist, zeige nur die Uhrzeit an. Wenn der Termin um 0 Uhr ist, ist die Uhrzeit IMMER Ganztägig. 
    return (
        <Link to={"/event/" + slug}>
            <BorderRadiusBox className={"EventItem " + className}>

                {/* <div className=" ratio ratio-4x3"> */}
                <div className="imageBox" >
                    <StrapiImage singleImageObj={titleImage} directUrl={titleImageSrc}>
                        <div className="p-2 cont">
                            {/* style={{ boxShadow: `8px -12px 24px 0 ${BlurColor}`, }} */}

                            <div className="dateBox">
                                {/* {startDate.getDate()} */}

                                {formatDate(startTimestamp, !endTimestamp)}
                                {endTimestamp && (
                                    <React.Fragment>
                                        <span className="small">bis</span>
                                        {formatDate(endTimestamp, false)}
                                    </React.Fragment>
                                )}
                            </div>


                            <span className="title">{title}</span>
                            <div className="subInfos">
                                {/* {titleImgSrc} */}
                                {/* <span className="price">{priceText} {averageImageColor(titleImgSrc)}</span> */}
                                <LocationText {...location} />
                            </div>
                        </div>
                    </StrapiImage>

                </div>
                {/* </div> */}

            </BorderRadiusBox>
        </Link>
    )
}
export default EventItem


function LocationText({ data }) {

    let { id, attributes } = data || {}
    let { title } = attributes || {}

    if (!title) return null
    return (
        <span className="location"> <i className="fa-solid fa-location-pin"></i> {title}</span >
    )
}


function createDateObject(dateString, timeString) {
    if (!dateString) {
        return null;
    }

    const dateParts = dateString.split('-');

    if (dateParts.length !== 3) {
        return null;
    }

    // Erstelle ein Date-Objekt mit Jahr, Monat und Tag
    const dateObject = new Date(
        parseInt(dateParts[0]),
        parseInt(dateParts[1]) - 1,
        parseInt(dateParts[2])
    );

    if (timeString) {
        const timeParts = timeString.split(':');

        if (timeParts.length !== 3) {
            return null;
        }

        // Setze die Stunden, Minuten und Sekunden des Date-Objekts
        dateObject.setHours(parseInt(timeParts[0]));
        dateObject.setMinutes(parseInt(timeParts[1]));
        dateObject.setSeconds(parseInt(timeParts[2]));
    } else {
        // Setze die Stunden, Minuten und Sekunden des Date-Objekts auf 0
        dateObject.setHours(0);
        dateObject.setMinutes(0);
        dateObject.setSeconds(0);
    }

    return dateObject;
}


function formatDate(date, showTime = true) {
    // Erstelle ein Datumsobjekt für heute
    const today = new Date();

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    //Vergangenheit (nur wenn es noch nicht vorbei ist)
    if (today > date) {
        let val = date.toLocaleDateString('de-DE', { day: 'numeric', month: 'short' });


        if (tomorrow.getTime() < date.getTime() + 7 * 24 * 60 * 60 * 1000) {
            return <>
                <span className="small">Seit letzten</span>
                <span className="big">{date.toLocaleDateString('de-DE', { weekday: 'long' })}</span>
            </>
        }


        return <>
            <span className="small">Seit</span>
            <span className="big">{val}</span>
        </>
    }


    // Wenn das angegebene Datum heute ist, gib "Heute um [Uhrzeit]" zurück
    if (date.toDateString() === today.toDateString()) {
        return <>
            <span className="big">Heute</span>
            {showTime ? <span className="small">{date.toLocaleTimeString().substring(0, 5)} Uhr</span> : null}
        </>
    }

    // Wenn das angegebene Datum morgen ist, gib "Morgen um [Uhrzeit]" zurück
    if (date.toDateString() === tomorrow.toDateString()) {
        let val = date.toLocaleTimeString().substring(0, 5)
        return <>
            <span className="big">Morgen</span>
            {showTime ? <span className="small">{val} Uhr</span> : null}
        </>
    }

    // Wenn das angegebene Datum diese Woche ist, gib "[Wochentag] um [Uhrzeit]" zurück
    if (date.getTime() < tomorrow.getTime() + 7 * 24 * 60 * 60 * 1000) {
        return <>
            <span className="big">{date.toLocaleDateString('de-DE', { weekday: 'long' })}</span>
            {showTime ? <span className="small">{date.toLocaleTimeString().substring(0, 5)} Uhr</span> : null}
        </>
    }

    // Wenn das angegebene Datum nächstes Jahr ist, gib "[Tag]. [Monat]" zurück
    const nextYear = new Date();
    nextYear.setFullYear(nextYear.getFullYear() + 1);
    if (date.getFullYear() === nextYear.getFullYear()) {
        let val = date.toLocaleDateString('de-DE', { day: 'numeric', month: 'short' });
        return <>
            <span className="big">{val}</span>
        </>
    }

    // Andernfalls gib "[Tag]. [Monat] [Jahr]" zurück
    let val = date.toLocaleDateString('de-DE', { day: 'numeric', month: 'short', year: 'numeric' });
    return <>
        <span className="big">{val}</span>
    </>
}
