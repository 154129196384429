import React, { useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import PagingListRender from "../../components/api/PagingListRender/PagingListRender"
import FilterBar from "../../components/defaults/boxes/FilterBar/FilterBar"
import EntryItem from "../../components/entries/EntryItem/EntryItem"
import ModernPage from "../../components/pages/ModernPage/ModernPage"
import "./EntryListPage.scss"

const DEFAULT_SORT = "?sort=level"

//create your forceUpdate hook
function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render
    // An function that increment 👆🏻 the previous state like here 
    // is better than directly setting `value + 1`
}

function EntryListPage({ defaultSort = DEFAULT_SORT }) {
    const forceUpdate = useForceUpdate();

    let { category } = useParams()
    let location = useLocation()
    let navigate = useNavigate()
    console.log(category, location.search)

    const [Filter, setFilter] = useState("")



    function handleFilter(filter) {
        let category = "restaurants"
        let baseUrl = `/list/${category}/`
        if (!filter || !filter.paramsString) {
            baseUrl += ""
            setFilter(defaultSort)

        } else {
            baseUrl += filter?.paramsString
            setFilter(filter.paramsString)
        }
        navigate(baseUrl)
        forceUpdate()
    }

    let endpoint = "/entries?populate=deep" //+ Filter

    console.log(endpoint)
    return (
        <ModernPage
            title={"Einträge"}
            className="EntryListPage Page"
            id={Filter}
            headerComponent={
                <FilterBar filters={defaultFilters} onFilterClick={handleFilter} />
            }
        >

            <div className="mt-3">
                <PagingListRender
                    endpoint={endpoint}
                    endpointFilter={location.search}
                    scrollableTarget="Page"
                    render={(i, k) => <EntryItem entryItem={i} key={k} />}
                />
            </div>
        </ModernPage>
    )
}
export default EntryListPage


const defaultFilters = [
    {
        title: "Test",
        paramsString: "?filters[slug][$eq]=dashdesign",
    },
    {
        title: "In der nähe",
        paramsString: null,
    },
    {
        title: "Neu",
        paramsString: "?sort=createdAt",
    },
    {
        title: "ABC",
        paramsString: "?sort=title%3Adesc",
    },
    {
        title: "Beliebt",
        paramsString: "?sort=level",
    }
]