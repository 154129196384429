import React from "react"
import "./BorderRadiusBox.scss"

function BorderRadiusBox({ children, className }) {
    return (
        <div className={"BorderRadiusBox  " + className}>
            <div className="inner">
                {children}
            </div>
        </div>
    )
}
export default BorderRadiusBox