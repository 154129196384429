import React from "react"
import "./TileItemSquare.scss"

function TileItemSquare({ icon, title, page }) {
    return (
        <div className="TileItemSquare">
            <div className="iconBox">
                <i className={`fa-duotone fa-${icon}`} />
            </div>
            <span className="title">{title}</span>
        </div>
    )
}
export default TileItemSquare