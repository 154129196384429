import React from "react"
import ListRender from "../../components/api/ListRender/ListRender"
import FilterBar from "../../components/defaults/boxes/FilterBar/FilterBar"
import EventItem from "../../components/events/EventItem/EventItem"
import TrashBanner from "../../components/more/banner/TrashBanner"
import ApplePage from "../../components/pages/ApplePage/ApplePage"
import { useApi } from "../../service/useApi"
import "./EventsListPage.scss"
const filterListPrice = [
    {
        title: "Kostenfrei",
    },
    {
        title: "Kostenpflichtig",
    },
    {
        title: "Mobile Lieferwagen",
    },
]
const filterList = [
    {
        title: "Events",
    },
    {
        title: "Regelmäßig",
    },
    {
        title: "Gesundheit",
    },
    {
        title: "Geschäftlich",
    },

    {
        title: "Dieses Wochenende",
    },
]

function EventsListPage({ }) {
    const { Data, Error, Loading } = useApi("/events")


    return (
        <ApplePage
            title={"Ereignisse"}
            className="EventsListPage"
            hideNavigateBack
            optionsText="Sortieren"
            onOptionClick={() => {

            }}>

            <TrashBanner />


            <FilterBar filters={filterListPrice} /><br />
            <FilterBar filters={filterList} /><br />

            <ListRender
                endpoint={"/events?sort=startDate:ASC&populate=*"}
                loader={<div></div>}
                render={i => <EventItem eventItem={i} />}
            />

            {/* <div className={"d-flex flex-column p-2 "}>
                {Error ? <ServerErrorPage /> : null}
                {Loading ?
                    <>
                        <GhostLoader_Event />
                        <GhostLoader_Event style={{ opacity: 0.75 }} />
                        <GhostLoader_Event style={{ opacity: 0.5 }} />
                    </>
                    :
                    Data.map((i, k) => <EventItem eventItem={i} key={k} />)
                }
            </div> */}
        </ApplePage>
    )
}
export default EventsListPage

const filter = [
    {
        title: "Kultur",
        icon: null,
    },
    {
        title: "Sport",
        icon: null,
    },
]