import Cookies from "js-cookie";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import NavigateBackButton from "../../defaults/buttons/NavigateBackButton";
import "./ApplePage.scss";

function ApplePage({
    smallOnly,
    children,
    subComponent,
    className,
    title,
    hideNavigateBack,
    optionsText,
    onOptionClick = () => { },
}) {
    let combinedClassName = "ApplePage Page " + className
    let marginTop = parseInt(Cookies.get("marginTop") || "8")

    const navigate = useNavigate()

    const titleBoxRef = useRef(null);
    const parentDivRef = useRef(null);


    function handleScroll() {
        const parentDiv = parentDivRef.current;
        const titleBox = titleBoxRef.current;
        if (parentDiv.scrollTop > 32 || smallOnly) {
            titleBox.classList.add('hidden');
            parentDiv.classList.add('scroll');
        } else {
            titleBox.classList.remove('hidden');
            parentDiv.classList.remove('scroll');
        }
        parentDiv.addEventListener('scroll', handleScroll);
        return () => {
            parentDiv.removeEventListener('scroll', handleScroll);
        };
    }

    // Scrollen menü 
    useEffect(() => {


        handleScroll()


    }, []);

    function navigateBack() {
        navigate(-1)
    }

    return (
        <div ref={parentDivRef} className={combinedClassName} id="Page" style={{ paddingTop: marginTop }}>
            <div className="Header" style={{ paddingTop: marginTop }}>
                <div className="d-flex flex-column">
                    <div className="container">
                        {
                            !hideNavigateBack ?
                                <NavigateBackButton asA><i className="fa-solid fa-chevron-left"></i> Zurück</NavigateBackButton>
                                :
                                <a></a>
                        }
                        <span className="title">{title}</span>
                        {optionsText ?
                            <a onClick={onOptionClick}>{optionsText}</a>
                            :
                            <a></a>
                        }
                    </div>
                    {subComponent &&
                        <div className="subComponent mt-3">
                            <div className="container">
                                {subComponent}
                            </div>
                        </div>
                    }
                </div>
            </div>

            <div className="container">
                <div className={!smallOnly && "mt-5"}>
                    <div>
                        <div className="TitleBox mb-4" ref={titleBoxRef}>
                            <h1>{title}</h1>
                        </div>
                        {subComponent}
                    </div>
                    {children}
                    <div className="push">

                    </div>
                </div>
            </div>
        </div>
    );
}
export default ApplePage