import React from "react"
import "./ImprintPage.scss"

function ImprintPage({ }) {
    return (
        <div className="ImprintPage Page">
            <h1>Impressum</h1>

            <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
            <p>
                dashdesign e.K.<br />
                {/* Konrad Mayer<br /> */}
                Poststr. 1<br />
                35305 Gr&uuml;nberg<br />
                <br />
                Inhaber: Konrad Mayer<br />
                Geschäftsführer: Konrad Mayer<br />
            </p>


            <p>Handelsregister: HRA 5091<br />
                Registergericht: Amtsgericht Gie&szlig;en</p>

            <h2>Kontakt</h2>
            <p>Telefon: 06634 96 90 99 - 7<br />
                E-Mail: info@dashdesign.eu</p>

            <h2>Umsatzsteuer-ID</h2>
            <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
                DE348868742</p>

            <h2>Angaben zur Berufs&shy;haftpflicht&shy;versicherung</h2>
            <p><strong>Name und Sitz des Versicherers:</strong><br />
                Allianz Versicherungs-Aktiengesellschaft<br />
                K&ouml;niginstr. 28<br />
                80802 M&uuml;nchen</p>
            <p><strong>Geltungsraum der Versicherung:</strong><br />Deutschland</p>

            <h2>Redaktionell verantwortlich</h2>
            <p>Johannes C. Ehler</p>

            <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
            <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
        </div>
    )
}
export default ImprintPage