import React from "react";
import "./ButtonBar.scss";

function ButtonBar({ buttons }) {
    console.log(buttons)
    return (
        <div className="ButtonBar">
            {buttons.map((i, k) => <ButtonBarItem {...i} key={k} />)}
        </div>
    )
}
export default ButtonBar

function ButtonBarItem({ title, url, icon, uri }) {

    return <a className="ButtonBarItem" href={url} target="_blank">
        <button>{icon} {title}</button>
    </a>
}

function getInfos(contactType) {
    let icon = "frog" //Fallback-Frog
    let iconPrefix = "fas" //Default: Duo Tone Icon
    let key = "Externe URL"
    let copyText

    switch (contactType) {

        // Wichtige Kontaktmög
        case "EMAIL":
        case "MAIL":
            icon = "envelope"
            key = "Mail-Adresse"
            copyText = "Du hast die Mail-Adresse kopiert. 📧"
            break;
        case "MENU":
            icon = "book-open"
            key = "Speisekarte"
            copyText = "Du hast die URL zur Speisekarte kopiert. 🍔"
            break;
        case "PHONE":
            icon = "phone"
            key = "Telefonnummer"
            copyText = "Du hast die Telefonnummer kopiert. 📲"
            break;
        case "WEBSITE":
            iconPrefix = "fab"
            icon = "chrome"
            key = "Website"
            copyText = "Du hast die Website-URL kopiert. ✅"
            break;
        case "MEETING":
            icon = "calendar"
            key = "Termin vereinbaren"
            copyText = "Du hast die URL zum Termine vereinbaren kopiert. 📅"
            break;
        // Soziale-Medien
        case "INSTAGRAM":
            icon = "instagram"
            iconPrefix = "fab"
            key = "Instagram"
            copyText = "Du hast die Instagram-URL kopiert. 📸"
            break;
        case "XING":
            icon = "xing"
            iconPrefix = "fab"
            key = "Xing"
            copyText = "Du hast die Xing-URL kopiert. 👨🏾‍🚀"
            break;
        case "FACEBOOK":
            icon = "facebook"
            iconPrefix = "fab"
            key = "Facebook"
            copyText = "Du hast die Facebook-URL kopiert. \n🤦🏻‍♂️📘"
            break;
        case "LINKEDIN":
            icon = "linkedin"
            iconPrefix = "fab"
            key = "Linkedin"
            copyText = "Du hast den verlinkten Link zu LinkedIn kopiert. 🔃"
            break;
        case "WHATSAPP":
            icon = "whatsapp"
            iconPrefix = "fab"
            key = "WhatsApp"
            copyText = "Du hast die WhatsApp-Nummer kopiert. 💬"
            break;

    }
    return ({ icon: { icon: icon, iconPrefix: iconPrefix, }, title: key || "URL", copyText: copyText })
}
