import React from "react"
import { Link } from "react-router-dom"
import "./TileItemModern.scss"

function TileItemModern({ icon, title, page, background }) {
    let { uri } = page
    return (
        <Link to={uri} className="TileItemModern">
            <div className="iconBox" style={{ background: background }}>
                <span className="title">{title}</span>


                <i className={`fa-${background ? "duotone" : "solid"} fa-${icon}`} />

            </div>
        </Link>
    )
}
export default TileItemModern