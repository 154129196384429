import React from "react"
import ApplePage from "../../components/pages/ApplePage/ApplePage"
import "./Page404.scss"

function Page404({ }) {
    return (
        <ApplePage title="Fehler 404" className="Page404">
            <h3>Diese Seite ist leider nicht verfügbar.</h3>
            <p>Entweder funktioniert der von dir angeklickte Link nicht oder die Seite wurde entfernt.</p>
        </ApplePage>
    )
}
export default Page404