import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import StreetPicker from "../../components/api/openStreetMap/StreetPicker"
import AppSettings from "../../service/settings/AppSettings"
import "./WelcomePage.scss"

function WelcomePage({ }) {

    let navigate = useNavigate()
    const [Page, setPage] = useState(0)

    let pages = getPages(handleNextPage)
    function handleNextPage() {
        console.log(pages.length - 1, " > ", Page)
        if (pages.length - 1 > Page) {
            console.log("next")
            setPage(Page + 1)
        } else {
            navigate("/events")
        }
    }

    return (
        <div className="WelcomePage">
            <div className="container p-3 pt-5 text-left circles">

                <div className="c1"></div>
                <div className="c2"></div>

                <div className="cont">
                    {pages[Page].content}
                </div>

            </div>
        </div>
    )
}
export default WelcomePage

const getPages = (nextPage) => [
    {
        content: <>
            <h1>Willkommen!</h1>
            <span className="subTitle">✨ Die neue Grünberg-App</span>
            <br />
            <br />
            <br />
            <p>Wir haben uns viel mühe gegeben, die Grünberg-App komplett von Grund auf neu zu entwickeln. </p>
            <p>Deswegen haben wir auch überarbeitete <Link to="/agb">AGB's</Link> und eine neue <Link to="/datenschutz">Datenschutzerklärung</Link>.
                Bitte lese diese gründlich durch und stimme diesen zu.</p>

            <div>
                <button onClick={nextPage} className="d-flex flex-row align-items-center">
                    <i className="fa-solid fa-check-circle"></i>
                    <div className="text-start">
                        Ich stimme den AGB's zu.
                    </div>
                </button>
            </div>

            <span className="moreOptions">Datenschutz anpassen</span>
        </>,
    },
    {
        content: <AddressSelection nextPage={nextPage} />
    },
    // {
    //     content: <AddressSelection nextPage={nextPage} />
    // },
]


function AddressSelection({ nextPage }) {

    function handleStreetSelect(street) {
        console.log(street)

        let { context, feature_name } = street
        let contextObj = extractProperties(context) || {}

        // if (!contextObj?.locality) {
        //     contextObj.locality == contextObj.place || ""
        // }

        let district = contextObj.locality || contextObj.place

        AppSettings.set("district", district)
        AppSettings.set("home", {
            street: feature_name,
            locality: contextObj.locality || contextObj.place,
            ...contextObj
            // city: street,feature_namex
        })

        nextPage()
    }
    return (
        <div className="AddressSelection">
            <h1>Wohnort wählen</h1>
            <span className="subTitle">Wir möchten Inhalte für dich anpassen.</span>
            <br />
            <br />
            <br />
            <p>Um dir einen Abfallkalender, Katastrophenwarnungen, Neuigkeiten von nebenan, Events und vieles mehr anzuzeigen, benötigen wir deine ungefähre Adresse.</p>

            {/* <div className="mb-1">
                <input className="w-100" placeholder="Adresse suchen"></input>
            </div> */}

            <div>
                <p>Straße suchen:</p>
                <StreetPicker onStreetSelect={handleStreetSelect} />
            </div>


            <br />


            <div className="absolute-b absolute-l absolute-r p-3 pb-0">
                <p>
                    <small><b>🔒 Datenschutz:</b> <br />Lokal gespeichert, Nicht Pesonenbezogen, 100% HTTPS-Verschlüsselt, keine Weitergabe an Dritte</small>
                    <br />
                    <small><Link to="/datenschutz/adresse">Wofür benötigen wir deine Adresse?</Link></small>
                </p>
            </div>
            {/* <small>Alle Inahlte werden</small> */}
        </div>
    )
}
//Sure! Here is a function that takes in an array of objects and returns an object with the properties postcode, locality, place, region, and country:
function extractProperties(arr) {
    const result = {};
    arr.forEach((item) => {
        result[item.layer] = item.name;
    });
    return result;
}



const addressTypes = [
    {
        title: "Straße",
        description: "Meldungen (z.B. 'Wasser wird abgestellt', 'Evakuierung wegen Bombenfund', uvm.)",
        components: [
            <input placeholder="Straße suchen" />
        ]
    },
    {
        title: "Stadt-/Ortsteil",
        description: "Abfallplan, Bekanntmachungen aus deinem Ort, Events um die Ecke",
        components: [
            <input placeholder="Ort suchen" />
        ]
    },
    {
        title: "Nur die Kommune",
        description: "Bist du Touristen oder Einheimisch",
        components: [
            <button>Grünberger:in</button>,
            <button>Tourist:in</button>
        ]
    },
]

// AGB & DSCGO
// Notifications & GPS Co.
// Adresse 
// Evtl: News & Co

{/* <h1>AGB</h1>
<Link to="/news">
    <button>Zustimmen</button>
</Link> */}