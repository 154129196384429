import { IsApplePlatform } from "../system/IsApplePlatform";

export function GetStartRoutePlanningUrl(coordsOrAddress) {
    if (IsApplePlatform()) {
        // Verwende Apple Maps
        return 'maps://?q=' + encodeURIComponent(coordsOrAddress);
    } else {
        // Verwende Google Maps
        return 'https://www.google.com/maps?q=' + encodeURIComponent(coordsOrAddress);
    }
}
