import React from "react"
import "./GhostLoader.scss"

function GhostLoader({ height = 128, width = "100%", borderRadius = 16, className }) {
    return (
        <div style={{ height: height, width: width, borderRadius: borderRadius }} className={"GhostLoader " + className}>

        </div>
    )
}
export default GhostLoader