import React from "react";
import { useNavigate } from "react-router-dom";
import "./ModernPage.scss";

function ModernPage({ headerComponent, children, className, title, }) {
    let combinedClassName = "ModernPage Page " + className

    const navigate = useNavigate()

    function navigateBack() {
        navigate(-1)
    }

    return (
        <div className={combinedClassName} id="Page">


            <div className="header pt-5">
                <div onClick={navigateBack} className="titleBox mb-2" >
                    <i class="fa-solid fa-chevron-left"></i>
                    <h1>{title}:</h1>
                </div>
                {headerComponent}
            </div>


            <div className="scroll">
                <div className="children">
                    {children}
                </div>
                <div className="push">

                </div>
            </div>

        </div>
    );
}
export default ModernPage