import React, { useState } from "react";
import Map, { FullscreenControl, GeolocateControl, Marker } from 'react-map-gl';
import "./DefaultMap.scss";

const AccessToken = 'pk.eyJ1IjoiZGFzaHBvbGwiLCJhIjoiY2xhNzB5djc5MDZ1eDNwb2JoYzZrZmljZCJ9.IyvNn46YJd2LGouXUZ5Sjw';

function DefaultMap({ children, coordinates = {} }) {

    const [lng, setLng] = useState(-70.9);
    const [lat, setLat] = useState(42.35);
    const [zoom, setZoom] = useState(15);

    // useEffect(() => {
    //     if (map.current) return; // initialize map only once
    //     map.current = new mapboxgl.Map({
    //         container: mapContainer.current,
    //         style: 'mapbox://styles/mapbox/streets-v11',
    //         center: coordinatesArray,
    //         zoom: zoom
    //     });
    //     //Neuladen beim größe ändern
    //     // map.current.on('load', function () {
    //     //     map.resize();
    //     // });


    // });

    if (coordinates?.longitude && coordinates?.latitude) {
        return (
            <div className="DefaultMap">

                <Map
                    initialViewState={{
                        longitude: coordinates.longitude,
                        latitude: coordinates.latitude,
                        zoom: zoom
                    }}
                    style={{ width: '100%', height: 200 }}
                    mapStyle="mapbox://styles/mapbox/streets-v9"
                    mapboxAccessToken={AccessToken}
                    dragRotate={false}
                    scrollZoom={false}
                    dragPan={false}
                >
                    <GeolocateControl />
                    <FullscreenControl />
                    <Marker {...coordinates} anchor="bottom" >
                        <i class="fa-sharp fa-solid fa-location-pin"></i>
                    </Marker>
                    {children}
                </Map>
            </div>
        )
    }
    else { return null }
}
export default DefaultMap