import React from "react"
import { useLocation } from "react-router-dom"
import "./WebViewPage.scss"

function WebViewPage({ }) {

    let { state } = useLocation()
    let { url } = state


    return (
        <div className="WebViewPage">
            <iframe src={url} />
        </div>
    )
}
export default WebViewPage