import React from "react"
import { Link } from "react-router-dom"
import StrapiImage from "../../api/strapi/StrapiImage/StrapiImage"
import "./AuthorBox.scss"

function AuthorBox({ author, publicTimestamp }) {

    if (!author) return null
    let { entry, banner, displayName, profileImage } = author || {}
    let { slug, title, } = entry?.data?.attributes || {}

    return (
        <Link to={"/" + slug} className="AuthorBox">

            {banner?.bannerImage?.data ?
                <Banner {...banner} />
                :
                <div>
                    <h4>{title}</h4>
                    <p>@{slug}</p>
                </div>
            }
            {/* <StrapiImage className="pb" singleImageObj={author?.profileImage} /> */}
            {/* <span className="name">{author.displayName}</span> */}
            {/* <span className="date">{formatTime(publicTimestamp)}</span> */}
        </Link>
    )
}
export default AuthorBox

function Banner(props) {

    let { bg, fg, bannerImage } = props
    return (
        <div className="Banner">
            <StrapiImage singleImageObj={bannerImage} />
            <div className="overlay">
                <FollowButton {...props} />
            </div>
            {/* Banner{bg}{fg} */}
        </div>
    )
}

function FollowButton({ bg, fg }) {

    function handleClick(e) {
        e.preventDefault();
        alert("Click auf Follow")
    }

    return <button className="FollowButton" onClick={handleClick} style={{ background: "#" + bg, color: "#" + fg }}>Folgen</button>
}