import Cookies from 'js-cookie';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useLayoutEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.scss";
import GhostLoader_App from './components/defaults/loader/GhostLoader_App/GhostLoader_App';
import BottomNavigation from "./components/navigation/BottomNavigation/BottomNavigation";
import AddressSelectionPage from './container/AddressSelection/AddressSelectionPage';
import CityPage from "./container/CityPage/CityPage";
import EntryListPage from "./container/EntryListPage/EntryListPage";
import EntryPage from './container/EntryPage/EntryPage';
import NoConnectionPage from './container/errors/NoConnectionPage/NoConnectionPage';
import EventPage from './container/EventPage/EventPage';
import EventsListPage from "./container/EventsListPage/EventsListPage";
import JobsPage from './container/JobsPage/JobsPage';
import MapPage from "./container/MapPage/MapPage";
import NewsPage from "./container/NewsPage/NewsPage";
import ImprintPage from "./container/options/law/ImprintPage/ImprintPage";
import PrivacyPage from "./container/options/law/PrivacyPage/PrivacyPage";
import OptionsPage from "./container/options/OptionsPage/OptionsPage";
import Page404 from './container/Page404/Page404';
import PostPage from './container/PostPage/PostPage';
import PropertyPage from './container/PropertyPage/PropertyPage';
import TrashPage from './container/TrashPage/TrashPage';
import WebViewPage from "./container/WebViewPage/WebViewPage";
import WelcomePage from './container/WelcomePage/WelcomePage';
import { useApi } from './service/useApi';
import './style/default.scss';
import './style/fonts.scss';
import LoadThemes from './style/LoadThemes';

const ScrollToTopWrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}


function App() {
  // Cookies.set("appName", "4") //! temp

  let appName = Cookies.get("appName") || "1"

  let { Data, Loading, Error } = useApi("/apps/" + appName)

  if (false) return (
    <header className='App'>
      <BrowserRouter>
        <AddressSelectionPage />
      </BrowserRouter>
    </header>
  )
  if (Loading) return <GhostLoader_App />
  if (Error) return <NoConnectionPage />
  if (Data) {

    let { appID, filter, DisplayName, theme } = Data.attributes

    Cookies.set("DisplayName", DisplayName)
    Cookies.set("appID", appID)

    return (
      <div>
        {/* <div>
          <small>Debug:</small>
          {appName}
          {JSON.stringify(Data)}
        </div> */}
        <BrowserRouter>
          <ScrollToTopWrapper>
            <LoadThemes />
            <div className={"App " + theme}>

              {/* <HeaderNavigation homeRoutes={HomeRoutes} subRoutes={SubRoutes} /> */}

              <div className='mainDiv'>
                <div className="pages">
                  <Routes>
                    {HomeRoutes.map((i, k) => <Route {...i} key={k} />)}
                    {SubRoutes.map((i, k) => <Route {...i} key={k} />)}
                  </Routes>
                </div>

                <div className="footerPush" />
              </div>

              <footer>
                <BottomNavigation homeRoutes={HomeRoutes} subRoutes={SubRoutes} />
              </footer>

            </div>
          </ScrollToTopWrapper>
        </BrowserRouter>

      </div>
    );
  }
}

export default App;

const HomeRoutes = [
  {
    element: <EventsListPage />,
    path: "/events",
  },
  {
    element: <NewsPage />,
    path: "/news",
  },
  {
    element: <MapPage />,
    path: "/map",
  },
  {
    element: <CityPage />,
    path: "/home",
  },
  // {
  //   element: <Page404 />,
  //   path: "*",
  //   title: "404",
  // },
]


const SubRoutes = [

  {
    element: <PostPage />,
    path: "/post/:postUrl",
  },

  {
    element: <WelcomePage />,
    path: "/",
  },

  {
    element: <EventPage />,
    path: "/event/:slug",
  },
  {
    element: <PropertyPage />,
    path: "/property/",
  },
  {
    element: <TrashPage />,
    path: "/trash/",
  },
  {
    element: <JobsPage />,
    path: "/jobs/",
  },
  // {
  //   element: <TankenPage />,
  //   path: "/tanken/",
  // },

  {
    element: <OptionsPage />,
    path: "/options",
  },
  {
    element: <ImprintPage />,
    path: "/options/imprint",
  },
  {
    element: <PrivacyPage />,
    path: "/options/privacy",
  },
  {
    element: <WebViewPage />,
    path: "/webview/",
  },
  {
    element: <EntryListPage />,
    path: "/list/",
  },
  {
    element: <EntryListPage />,
    path: "/list/:category/",
  },
  {
    element: <EntryListPage />,
    path: "/search/",
  },


  {
    element: <EntryPage />,
    path: "/:slug",
  },
  {
    element: <Page404 />,
    path: "/login/",
    title: "404",
  },
  {
    element: <Page404 />,
    path: "*",
    title: "404",
  },
]