import React, { useState } from "react"
import ApplePage from "../../components/pages/ApplePage/ApplePage"
import "./CityPage.scss"

function CityPage({ }) {

    const [City, setCity] = useState(0)

    console.log(demoData)

    return (
        <ApplePage title={"Amtswege"} className="CityPage" hideNavigateBack>

            <div className="mt-3 mb-4 cityHeaderBox">
                {
                    demoData.map((i, k) =>
                        <span
                            className={k == City ? "cityHeading active" : "cityHeading"}
                            onClick={() => setCity(k)}
                        >{i.title}</span>
                    )
                }
            </div>
            {/* <button onClick={() => alert("Diese Funktion ist in der Web-Demo nicht verfügbar.")}><i className="fa-solid fa-sparkles"></i> Hungen hinzfügen?</button> */}
            <hr className="mt-2 mb-4" />
            <div>
                <CityBox cityMenus={demoData[City].data} />
            </div>
        </ApplePage>
    )
}
export default CityPage

function CityBox({ cityMenus }) {
    return cityMenus.map((i, k) => <TileMenu {...i} key={k} />)
}

function TileMenu({ title, icon, desc, items, }) {
    return (
        <div className="TileMenu">
            <h3>{title}:</h3>
            <div>
                {items.map((i, k) =>
                    <LongTile {...i} key={k} />
                )}
            </div>
        </div>
    )
}

function LongTile({ title, icon, url }) {
    return (
        <a className="LongTile" href={url} target={"_blank"}>
            <div className="iconBox">
                {icon}
            </div>
            <span className="title">{title}</span>
        </a>
    )
}
const gbDemoData = [
    {
        title: "Beliebt",
        icon: <></>,
        desc: "",
        items: [
            {
                title: "Mängelmelder",
                icon: <i className="fa-solid fa-calendar-plus"></i>,
                url: "https://include-he.zfinder.de/374537076"
            },
            {
                title: "Das digitale Rathaus",
                icon: <i className="fa-solid fa-desktop"></i>,
                url: "https://www.gruenberg.de/rathaus-buergerservice/service/das-digitale-rathaus/"
            },

        ]
    },
    {
        title: "Hund",
        icon: <></>,
        desc: "",
        items: [
            {
                title: "Hund anmelden",
                icon: <i className="fa-solid fa-dog"></i>,
                url: "https://portal-civ.ekom21.de/civ.public/start.html?oe=00.00.GB.II.A&mode=cc&cc_key=AnmeldungHund"
            },
            {
                title: "Hund abmelden",
                icon: <i className="fa-solid fa-dog"></i>,
                url: "https://portal-civ.ekom21.de/civ.public/start.html?oe=00.00.GB.II.A&mode=cc&cc_key=AbmeldungHund"
            },
        ]
    },
    {
        title: "Sonstige Links",
        icon: <></>,
        desc: "",
        items: [
            {
                title: "Ratsinfosystem",
                subTitle: "Ämter, Gremien, Fraktionen, Personen und vieles mehr",
                icon: <i className="fa-solid fa-desktop"></i>,
                url: "https://rim.ekom21.de/gruenberg/"
            },
            {
                title: "Offizielle Website",
                icon: <i className="fa-solid fa-globe"></i>,
                url: "https://www.gruenberg.de/"
            },
            {
                title: "Instagram",
                subTitle: "@stadt_gruenberg",
                icon: <i className="fa-brand fa-instagram"></i>,
                url: "https://www.instagram.com/stadt_gruenberg/"
            },
        ]
    },
]


const mueckeDemoData = [
    {
        title: "Termine vereinbaren",
        icon: <></>,
        desc: "",
        items: [
            {
                title: "Einwohnermelde- und Passamt",
                icon: <i className="fa-solid fa-calendar-plus"></i>,
                url: "https://outlook.office365.com/owa/calendar/GemeindeverwaltungMcke1@gemeinde-muecke.de/bookings/"
            },
            {
                title: "Onlinetermin Zulassungsstelle",
                icon: <i className="fa-solid fa-calendar-plus"></i>,
                url: "https://outlook.office365.com/owa/calendar/GemeindeverwaltungMcke@gemeinde-muecke.de/bookings/"
            },
        ]
    },
    {
        title: "Engagement & Hobby",
        icon: <></>,
        desc: "",
        items: [
            {
                title: "Versammlung anmelden",
                icon: <i className="fa-solid fa-users"></i>,
                url: "https://portal-civ.ekom21.de/civ.public/start.html?oe=00.00.MUE.1.3.2&mode=cc&cc_key=Versammlung"
            },
            {
                title: "Feuerwerk beantragen",
                icon: <i className="fa-solid fa-face-party"></i>,
                url: "https://portal-civ.ekom21.de/civ.public/start.html?oe=00.00.MUE.1.3.2&mode=cc&cc_key=Feuerwerk"
            },
            {
                title: "Genehmigung Brauchtumsfeuer beantragen",
                icon: <i className="fa-solid fa-fire"></i>,
                url: "https://portal-civ.ekom21.de/civ.public/start.html?oe=00.00.MUE.1.3.2&mode=cc&cc_key=Feuerwerk"
            },
        ]
    },
]

const gi = [

    {
        title: "Beliebt",
        desc: "",
        items: [
            {
                title: "Corona-Test buchen",
                url: "https://test-hungen.probatix.de/de/pick-slot",
                icon: <i className="fa-regular fa-virus"></i>,
                popular: true,
            },
            {
                title: "Radroutenplaner",
                url: "https://radroutenplaner.hessen.de/",
                icon: <i className="fa-regular fa-bicycle"></i>,
                popular: true,
            },
        ]
    },
    {
        title: "Sonstiges",
        desc: "",
        items: [
            {
                title: "Offizielle Website",
                url: "https://www.lkgi.de/",
                icon: <i className="fa-regular fa-globe"></i>,
            },
            {
                title: "Instagram",
                subTitle: "@landkreisgiessen",
                url: "https://www.instagram.com/landkreisgiessen/",
                icon: <i className="fa-brands fa-instagram"></i>,
            },
        ]
    },
    {
        title: "Covid19",
        desc: "",
        items: [
            {
                title: "CORONA-Informationen",
                url: "https://corona.lkgi.de/",
                icon: <i className="fa-solid fa-shield-virus"></i>,
            },
            {
                title: "Corona-Test buchen",
                url: "https://test-hungen.probatix.de/de/pick-slot",
                icon: <i className="fa-regular fa-virus"></i>,
                popular: true,
            },
            {
                title: "Rechtliche Regeln",
                url: "https://corona.lkgi.de/aktuelles/rechtliche-regeln/",
                icon: <i className="fa-solid fa-head-side-mask"></i>,
            },
        ]
    },
    {
        title: "Hessen",
        desc: "",
        items: [
            {
                title: "Führungszeugnis",
                url: "https://www.fuehrungszeugnis.bund.de/ffw/action/invoke.do?id=AntragFZ",
                icon: <i className="fa-solid fa-handcuffs"></i>,
            },
            {
                title: "Radroutenplaner",
                url: "https://radroutenplaner.hessen.de/",
                icon: <i className="fa-regular fa-bicycle"></i>,
                popular: true,
            },

        ]
    },
]

const demoData = [
    {
        title: "Grünberg",
        data: gbDemoData,
    },
    {
        title: "Mücke",
        data: mueckeDemoData,
    },
    {
        title: "LK-Gi",
        data: gi,
    },
]
