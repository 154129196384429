import React from "react";
import { useParams } from "react-router-dom";
import ItemRender from "../../components/api/ItemRender/ItemRender";
import ApplePage from "../../components/pages/ApplePage/ApplePage";
import "./EventPage.scss";

function EventPage({ }) {

    let { slug } = useParams();
    if (!slug) return <p>Etwas läuft hier schief.</p>

    return (
        <ApplePage title={slug} className="EventPage">
            {slug}
            <ItemRender
                render={i => <RenderEventPage {...i[0]} />}
                endpoint={"/events?populate=*&filters[slug][$eq]=" + slug}
            />
        </ApplePage>
    )
}
export default EventPage

function RenderEventPage(props) {

    let { id, attributes } = props
    let { title, createdAt, updatedAt, publishedAt, description,
        requiredAge, moreInfoUrl, bookingUrl, priceText, startDate,
        startTime, endDate, endTime, slug, media, location
    } = attributes || {}

    return (
        <>
            {/* <MediaCarousel data={media} /> */}
            <h1>{title}</h1>
            <p>{description}</p>

            <h4>Preis:</h4>
            <p>{priceText}</p>

            <UrlButton url={moreInfoUrl} title="Mehr Infos" />
            <UrlButton url={bookingUrl} title="Ticket buchen" />
            <UrlButton url={slug} title="Teilen" />

            <p>{JSON.stringify(location)}</p>
        </>
    )
}

function UrlButton({ url, title }) {
    if (!url) return null
    return (
        <a href={url} target="_blank">
            <button>{title}</button>
        </a>
    )
}