import React from "react"
import GhostLoader from "../GhostLoader"
import "./GhostLoader_App.scss"

function GhostLoader_App({ }) {
    return (
        <div className="GhostLoader_App">
            <div className="mb-2">
                <GhostLoader height={64} width={"100vw"} />
            </div>



            <div className="d-flex flex-center align-items-center author mb-2">
                <GhostLoader width={"calc(100vw - 32px)"} height={"75vh"} borderRadius={9} />
            </div>

        </div>
    )
}
export default GhostLoader_App