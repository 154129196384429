import React from "react"
import { Link } from "react-router-dom"
import formatTime from "../../../functions/formartTime"
import BorderRadiusBox from "../../defaults/boxes/BorderRadiusBox/BorderRadiusBox"
import AuthorBox from "../AuthorBox/AuthorBox"
import "./PostItem.scss"

function PostItem({ postData, fullArticle = false }) {

    // console.log(postData)

    const postUrl = "/post/" //+ encrypt(postData.url)



    return (
        <a className="PostItem p-0" href={postData.url} target="_blank">
            <BorderRadiusBox  >
                <AuthorBox author={postData.author?.data?.attributes} publicTimestamp={postData.publicTimestamp} />
                {postData.titleImage ?
                    <img className="titleImage w-100" src={postData.titleImage} />
                    :
                    null
                }
                <div className="content">
                    <h3>{postData.title}</h3>

                    {postData.html || !postData.actionText ?
                        <div className="postContent">
                            {/* {fullArticle ?
                                <p>{"postData.html"}</p>
                                :
                            } */}
                            {!fullArticle && <Link to={postUrl} state={postData}><button>Artikel öffnen</button></Link>}
                        </div>
                        :
                        <div>
                            <p>{postData.abstract}</p>
                            <button>{postData.actionText}</button>
                        </div>
                    }

                    <div className="pt-2">
                        <span className="date">Veröffentlicht {formatTime(postData.publicTimestamp)}</span>
                    </div>
                </div>
            </BorderRadiusBox>
        </a>
    )
}
export default PostItem

function encrypt(id) {
    encrypt('a', id);
}

function decrypt(data) {
    decrypt('a', 'data');
}
