import React from "react"
import "./OpeningTimes.scss"

function OpeningTimes({ openingTimes }) {
    if (!openingTimes) return null

    let { hours, text, visible } = openingTimes

    return (
        <div className="OpeningTimes mb-4">
            <h3>Öffnungszeiten</h3>
            <p>{text}</p>
            {visible &&
                <RenderHours hours={hours} />
            }
        </div>
    )
}
export default OpeningTimes

function RenderHours({ hours }) {
    //Prüfen ob Mo-Fr Same
    //Prüfen ob Sa - So Same
    if (hours == []) {
        return <h4>Nicht geöffnet.</h4>
    }

    return (
        dayNamesHuman.map((i, k) =>
            <div key={k} className="RenderHours">
                <span className="dayName">{i}.</span>
                <div>
                    {hours[i].map((h, k2) =>
                        <TimeSpanItem timeSpan={h} key={k2} />
                    )}
                </div>
                {hours[i].length == 0 ? <span className="closed">Geschlossen</span> : null}
            </div>
        )
    )
}
const dayNamesHuman = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"]

function TimeSpanItem({ timeSpan = [] }) {
    if (timeSpan[0] && timeSpan[1])
        return (
            <div className="TimeSpanItem">
                <span>{timeSpan[0]} - {timeSpan[1]} Uhr</span>
            </div>
        )
    return null
}