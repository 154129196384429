import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import PagingListRender from "../../components/api/PagingListRender/PagingListRender"
import FilterBar from "../../components/defaults/boxes/FilterBar/FilterBar"
import ApplePage from "../../components/pages/ApplePage/ApplePage"
import PostItem from "../../components/post/PostItem/PostItem"
import AppSettings from "../../service/settings/AppSettings"
import "./NewsPage.scss"

function NewsPage({ }) {
    const navigate = useNavigate();
    const [Filter, setFilter] = useState()
    let district = AppSettings.get("district")
    const filterList = [
        {
            title: district ? "Für " + district : "Für dich",
            endpointParamsString: "&filters[author][displayName][$contains]=" + district
        },
        {
            title: "Von der Stadt",
            endpointParamsString: "&filters[author][ID][$eq]=" + 1 //Author ID 1
        },
        // {
        //     title: "Von der Stadt",
        //     endpointParamsString: "&filters=[author][ID][$eq]=" + 1 //Author ID
        // },
        {
            title: "GWG",
            endpointParamsString: "&filters[author][ID][$eq]=" + 7 //Author ID 7
        },
        {
            title: "Umfragen",
            endpointParamsString: "&filters[author][displayName][$eq]=" + "fi9rui8gtu8gujiutjifgjko",
        },
        {
            title: "Entwickler-Updates",
            endpointParamsString: "&filters[$or][0][author][ID][$eq]=8&filters[$or][1][author][ID][$eq]=4"
        }
    ]

    function handleLogin() {
        navigate('/login');
    }
    let endpoint = "/articles?populate[0]=author&populate[1]=author.profileImage&populate[2]=author.banner&populate[3]=author.banner.bannerImage&populate[4]=author.entry&sort=publicTimestamp:DESC"

    return (
        <ApplePage
            title="Neuigkeiten"
            className="NewsPage"
            hideNavigateBack
            optionsText="Login"
            onOptionClick={handleLogin}
        >
            <FilterBar onFilterClick={filter => setFilter(filter)} filters={filterList} />
            <div className="posts">
                <PagingListRender
                    endpointFilter={Filter?.endpointParamsString}
                    scrollableTarget="Page"
                    endpoint={endpoint}
                    render={(i, k) => <PostItem postData={i} key={k} />}
                />
            </div>
        </ApplePage>
    )
}
export default NewsPage