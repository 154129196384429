import React from "react"
import TileMenu from "../TileMenu/TileMenu"
import "./TileMenuList.scss"

function TileMenuList({ menus }) {

    return (
        <div className="TileMenuList">
            {menus.map((i, k) => <TileMenu {...i} key={k} />)}
        </div>
    )
}
export default TileMenuList