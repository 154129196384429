import React from "react"

import "./themes/gruenberg_gruen.scss"
import "./themes/mittelhessen_blue.scss"
import "./themes/red.scss"

function LoadThemes({ }) {
    return (
        <div className="LoadThemes">

        </div>
    )
}
export default LoadThemes