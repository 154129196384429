import React from "react"
import ButtonBar from "../../../defaults/boxes/ButtonBar/ButtonBar"
import "./FastButtons.scss"


function FastButtons({ contactOptions = [] }) {

    console.log(contactOptions)

    return (
        <div className="FastButtons py-4">
            <ButtonBar buttons={convertContactLinks(contactOptions)} />
        </div>
    )
}
export default FastButtons


//Baut die Links von Strapi in das Formart für ButtonBar um
function convertContactLinks(links = []) {
    return links.map((i, k) => {
        return {
            ...i,
            icon: getIconHtml(i.icon)
        }
    })
}

function getIconHtml(icon) {

    icon = icon.replace("fa-", "")

    switch (icon) {
        case "facebook":
        case "instagram":
        case "xing":
        case "telegram":
        case "google":
        case "twitter":
        case "tiktok":
            return <i className={"fa-brands fa-" + icon}></i>
        default:
            return <i className={"fa-regular fa-" + icon}></i>
    }
}