import axios from "axios"
import { useEffect, useState } from "react"
import { Strapi } from "../const/api/endpoints"


export function useApi(endpoint) {
    const [Data, setData] = useState({})
    const [Error, setError] = useState(false)
    const [Loading, setLoading] = useState(true)

    useEffect(() => {
        (
            async function () {
                setError(false)
                setLoading(true)

                let config = {
                    method: 'get',
                    url: Strapi.API_ADDRESS + endpoint,
                    headers: {
                        'Authorization': `Bearer ${Strapi.API_TOKEN}`
                    },
                    cacheDurationInMinutes: 10,
                };

                // alenaxios(config)
                axios(config)
                    .then(res => {
                        setData(res.data?.data || res.data || {})
                    })
                    .catch(() => setError(true))
                    .finally(() => setLoading(false))
            }
        )()
    }, [])

    return { Data, Loading, Error }
}