import axios from "axios"
import React, { useEffect, useState } from "react"
import InfiniteScroll from 'react-infinite-scroll-component'
import { Strapi, STRAPI_API_ADDRESS } from "../../../const/api/endpoints"
import GhostLoader from "../../defaults/loader/GhostLoader"
import EntryItem from "../../entries/EntryItem/EntryItem"
import "./PagingListRender.scss"

const LIMIT = 10;
const DEFAULT_CLASSES = "NewsItem col-12 col-md-6 col-xxl-4 p-3"
const LOADER = <div className="row">
    <p>Lädt...</p>
    <GhostLoader />
</div>
//create your forceUpdate hook
function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render
    // An function that increment 👆🏻 the previous state like here 
    // is better than directly setting `value + 1`
}

function PagingListRender({ searchText, endpoint, endpointFilter, categories, itemClassName = DEFAULT_CLASSES, className, render = EntryItem, scrollableTarget = "Page" }) {
    const [articles, setArticles] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const offset = articles.length;
    const forceUpdate = useForceUpdate();

    async function fetchArticles(endpointFilter2 = "") {
        const page = offset / LIMIT;
        let url = STRAPI_API_ADDRESS + `${endpoint + endpointFilter2}&pagination[page]=${page}&pagination[pageSize]=${LIMIT}`;

        console.log(url)

        // if (searchText2) {
        //     url += `&search=${searchText2}`;
        // }
        // if (categories2 && categories2.length > 0) {
        //     url += `&categories=${categories2.join(',')}`;
        // }

        const response = await axios.get(url, {
            headers: {
                'Authorization': `Bearer ${Strapi.API_TOKEN}`
            },
        });
        const data = response.data;
        setArticles(articles => [...articles, ...data?.data]);
        setHasMore(data?.data.length === LIMIT);
    }

    function reset() {
        setHasMore(() => {
            fetchArticles(endpointFilter);
            return []
        })
        setHasMore(() => {
            fetchArticles(endpointFilter);
            return true
        })

        forceUpdate()
    }
    useEffect(() => {
        reset()
    }, [searchText, categories, endpointFilter]);

    return (
        <div className="PagingListRender">
            {searchText && <p>Mindestens {articles.length} Artikel gefunden.</p>}
            {searchText && articles.length == 0 && <p>Keine Artikel gefunden.</p>}
            <InfiniteScroll
                dataLength={articles.length}
                next={fetchArticles}
                // scrollThreshold={1} 
                hasMore={hasMore}
                loader={LOADER}
                endMessage={
                    <p style={{ textAlign: 'center' }}>
                        <hr />
                        <b>Fehlt ein Eintrag?</b>
                        <p>Bitte helfe uns dabei diese App zu verbessern und melde uns den Eintrag.</p>
                        <a href="https://dashdesign.eu/kontakt" target={"_blank"}>
                            <button>Kontakt aufnehmen</button>
                        </a>
                    </p>
                }
                scrollableTarget={scrollableTarget}
                refreshFunction={reset}
                pullDownToRefresh
                pullDownToRefreshThreshold={50}
                pullDownToRefreshContent={
                    <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
                }
                releaseToRefreshContent={
                    <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
                }
            >
                {articles.map((article, k) => (
                    // render(article)
                    // <EntryItem className={itemClassName} {...article.attributes} key={k} />
                    render({ className: itemClassName, key: k, ...article.attributes }, k)
                ))}
            </InfiniteScroll>
        </div>
    );
}
export default PagingListRender
