import React from "react"
import getSrcFromSingleComponent from "../../../../functions/strapi/getSrcFromSingleComponent"
import "./StrapiImage.scss"

function StrapiImage({ singleImageObj, children, asBackground, directUrl }) {
    let img = directUrl //Um ein Bild direkt zu laden (zB bei den Events)
    if (!img) {
        img = getSrcFromSingleComponent(singleImageObj)
    }
    return (
        <div className="StrapiImage">

            {asBackground || children ?
                <div className="imgDiv" style={{ backgroundImage: `url(${img})` }} >{children}</div>
                :
                <img src={img} />
            }
        </div>
    )
}
export default StrapiImage


