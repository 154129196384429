import React from "react";
import { useParams } from "react-router-dom";
import ListRender from "../../components/api/ListRender/ListRender";
import LocationView from "../../components/api/openStreetMap/LocationView/LocationView";
import FastButtons from "../../components/entries/entryPage/FastButtons/FastButtons";
import ImageList from "../../components/entries/entryPage/ImageList/ImageList";
import OpeningTimes from "../../components/entries/entryPage/OpeningTimes/OpeningTimes";
import ApplePage from "../../components/pages/ApplePage/ApplePage";
import Page404 from "../Page404/Page404";
import "./EntryPage.scss";

function EntryPage({ }) {

    let { slug } = useParams();

    if (!slug) return <Page404 />
    return (
        <ListRender
            error={<Page404 />}
            empty={<Page404 />}
            endpoint={"/entries?populate=deep&filters[slug][$eq]=" + slug}
            render={(Data) =>
                <RenderEntryPage {...Data} />
            }
        />
    )
}
export default EntryPage

function RenderEntryPage(entryItem) {
    let { title, slug, subTitle, website, description, oldMediaJson, openingTimes, type, type2, address, createdAt, keywords, media, contactOptions, subEntries, parentEntry, coordinates } = entryItem || {}

    return (
        <ApplePage smallOnly title={"@" + slug} className="EntryPage">

            <ImageList images={oldMediaJson} />

            <div className="text-center">
                <span className="title">{title}</span>
                <span className="subTitle">{subTitle}</span>
                {/* <span className="address">{address}</span> */}
            </div>

            <hr />

            <div className="row ">

                <div className="">
                    <FastButtons contactOptions={contactOptions} />
                </div>

                <div className="col-12 col-lg-8">
                    <div className="mb-4">
                        <h3>Beschreibung</h3>
                        <p>{description || "Keine Beschreibung verfügbar."}</p>
                    </div>
                    <div className="mb-4">
                        <h3>Karte</h3>
                        <LocationView title={title} address={address} coordinates={coordinates} />
                    </div>
                    {/* <CoronaBox entryData={Data} /> */}
                    {/* <TeamBox entryData={Data} /> */}
                </div>
                <div className="col-12 col-lg-4">
                    <OpeningTimes openingTimes={openingTimes} />
                    {/* <LawBox entryData={Data} /> */}
                </div>
            </div>




            {/* <ButtonBar buttons={contactOptions} /> */}

            {/* {JSON.stringify(Data)} */}
            {/* <div className="container mt-5 pt-4">
        <ImageList images={Data.payload.images} />

    
       

        <hr></hr>

        <div className="row pt-2">
            <div className="col-12 col-lg-9">

                <div className="mb-4">
                    <h3>Beschreibung</h3>
                    <p>{Data.general.description || "Keine Beschreibung verfügbar."}</p>
                </div>

                <div className="mb-4">
                    <CoronaBox entryData={Data} />
                </div>

                <LawBox entryData={Data} />
                <TeamBox entryData={Data} />

            </div>
            <div className="col-12 col-lg-3">
                <OpeningTimes entryData={Data} />
            </div>
        </div>

    </div> */}
        </ApplePage>
    )
}