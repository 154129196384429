import React, { useState } from "react";
import NavigateBackButton from "../../components/defaults/buttons/NavigateBackButton";
import ApplePage from "../../components/pages/ApplePage/ApplePage";
import getSrcFromSingleComponent from "../../functions/strapi/getSrcFromSingleComponent";
import AppSettings from "../../service/settings/AppSettings";
import { GetTrashCalender } from "../../service/trash/GetTrashCalender";
import UnavailableInCityPage from "../UnavailableInCityPage/UnavailableInCityPage";
import "./TrashPage.scss";

const blogURL = "https://blog.dashdesign.eu/2023/01/04/kalender-synchronisieren-leicht-gemacht-wie-man-ics-dateien-importiert/"

function TrashPage({ }) {

    const [Downloaded, setDownloaded] = useState(false)

    function hideBanner() {
        AppSettings.set(AppSettings.Setting.TRASH_CALENDAR_SAVED, true);
    }

    function showBanner() {
        AppSettings.set(AppSettings.Setting.TRASH_CALENDAR_SAVED, false);
    }

    function saveCalendar() {
        setDownloaded(true)
        // ! Download erfolgt über Link 
        hideBanner() //Banner weg
        AppSettings.set(AppSettings.Setting.TRASH_ASK_IF_CALENDER_SAVED_IS_OKAY, true)
    }


    const { Data, Loading, Error } = GetTrashCalender(2)
    if (Loading) return <p>dkkfi</p>
    if (Error) return <p>Error</p>
    if (Data.length == 0) return <UnavailableInCityPage />
    if (Data.length > 1) return <p>Mehrere gefunden</p>
    if (Data) {

        let file = Data[0].attributes?.icalFile
        let src = getSrcFromSingleComponent(file)

        if (!src) return <p>Error</p>

        return (
            <ApplePage title={"Abfallpalan"} className="TrashPage">



                {
                    Downloaded ? <div>
                        <h3>Du wurdest nicht weitergeleitet?</h3>
                        <p>Öffne die heruntergeladene Datei mit deiner Kalender-App.</p>
                        <div className="d-flex flex-column mb-5">
                            {/* <a href={src} onClick={saveCalendar}>
                                <button className="main">
                                    <i className="fa-light fa-calendar-plus"></i> Erneut versuchen
                                </button>
                            </a> */}
                            <a>
                                <NavigateBackButton><i className="fa-duotone fa-check"></i>Fertig</NavigateBackButton>
                            </a>
                        </div>
                        <div>
                            <p><small>Probleme?</small></p>
                            <p><a href={blogURL} target="_blank"><i className="fa-solid fa-arrow-up-right-from-square"></i> Anleitung: Kalender-Datei speichern</a></p>
                            <p><a target="_blank" href={src} onClick={saveCalendar}><i className="fa-solid fa-solid fa-rotate-right"></i> Datei erneut laden</a></p>
                        </div>
                    </div>
                        :
                        <div>
                            <p>Der Abfallplan für <b>Weitershain</b> wird zu deinem Standard-Kalender hinzugefügt.</p>
                            <a className="d-block mb-5" href={src} onClick={saveCalendar}>
                                <button>
                                    <i className="fa-light fa-calendar-plus"></i><OperatingSystemMessage />
                                </button>
                            </a>
                        </div>
                }


                {/* <button onClick={showBanner}>Show</button> */}

            </ApplePage >
        )
    }
}
export default TrashPage




function OperatingSystemMessage() {
    const userAgent = navigator.userAgent;

    let message;
    switch (true) {
        case userAgent.includes('Linux'):
            message = 'Datei herunterladen (.ICS)';
        case userAgent.includes('Windows'):
        case userAgent.includes('Chrome'):
            message = 'Datei herunterladen';
        case userAgent.includes('Android'):
            message = 'Zum Kalender hinzufügen';
            break;
        case userAgent.includes('Mac OS X'):
            if (userAgent.includes('Safari') || userAgent.includes('iPad')) {
                message = 'Zum Kalender hinzufügen';
            } else if (userAgent.includes('iPhone')) {
                message = 'Kalender abonnieren';
            }
            else {
                message = 'Datei herunterladen';
            }
            break;
        default:
            message = 'Datei herunterladen';
    }

    return message;
}

