import React from "react"
import { Link } from "react-router-dom"
import "./OptionsPage.scss"

function OptionsPage({ }) {
    return (
        <div className="OptionsPage Page">
            <h1 className="">Einstellungen</h1>
            <hr className="mb-5" />
            {
                optionPages.map((i, k) =>
                    <div className="mb-3">
                        <h3 className="mb-4">{i.title}</h3>
                        <div>
                            {i.tile.map((j, kk) =>
                                <Link to={j.uri}>
                                    <div className="mb-4">
                                        <h4>{j.title}</h4>
                                        <p>{j.subTitle}</p>
                                    </div>
                                </Link>
                            )}
                        </div>
                    </div>
                )
            }
        </div>
    )
}
export default OptionsPage


const optionPages = [
    {
        title: "Weitere Infos",
        tile: [
            {
                title: "Impressum",
                subTitle: "Unser Impressum",
                uri: "/options/imprint"
            },
            {
                title: "Datenschutz",
                subTitle: "Erfahre wie wir deine Daten schützen.",
                uri: "/options/privacy"
            },
        ],
    }
]