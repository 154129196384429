import React from "react";
import { Link } from "react-router-dom";
import AppSettings from "../../../service/settings/AppSettings";
import "./TrashBanner.scss";

function TrashBanner({ }) {
    let showBanner = AppSettings.get(AppSettings.Setting.TRASH_CALENDAR_SAVED) != true

    if (showBanner)
        return (
            <div className="mb-5">
                <h2><i className="fa-solid fa-sparkles"></i> Empfehlungen</h2>
                <Link to="/trash/">
                    {AppSettings.get(AppSettings.Setting.TRASH_CALENDAR_SAVED)}
                    <div className="TrashBanner">
                        <img className="w-100" src="https://blog.dashdesign.eu/wp-content/uploads/2023/01/trash-3.png" />
                        <button><i className="fa-light fa-calendar-plus"></i> Abfallpan speichern</button>
                    </div>
                </Link>
            </div>

        )

}
export default TrashBanner