import React from "react"
import { ParseAddress } from "../../../../functions/map/ParseAddress"
import { GetStartRoutePlanningUrl } from "../../../../functions/map/RoutePlaning"
import DefaultMap from "../DefaultMap/DefaultMap"
import "./LocationView.scss"

function LocationView({ title, address, coordinates }) {

    if (!coordinates) return <p>Keine Adresse verfügbar.</p>

    let routeUrl = ""
    try {

        let cordsEnabled = (coordinates?.latitude && coordinates?.longitude)
        let cordsString = cordsEnabled && `${coordinates.latitude},${coordinates.longitude}`
        let routeString = address || cordsString || "Keine Adresse"
        routeUrl = GetStartRoutePlanningUrl(routeString)
    }
    catch (e) {
        return <div><p>Keine Adresse</p></div>
    }

    return (
        <div className="LocationView">
            <a href={routeUrl} target="_blank">
                <DefaultMap coordinates={coordinates} />
                <div className="d-flex flex-row justify-content-between align-items-baseline">
                    <p className="p-1">{ParseAddress(address)}</p>
                    <button>Navigation starten <i class="fa-solid fa-arrow-right"></i> </button>
                </div>
            </a>
        </div>
    )
}
export default LocationView

