import axios from 'axios';
import React from 'react';

const token = "pk.eyJ1Ijoic2VhcmNoLW1hY2hpbmUtdXNlci0xIiwiYSI6ImNrNnJ6bDdzdzA5cnAza3F4aTVwcWxqdWEifQ.RFF7CVFKrUsZVrJsFzhRvQ"
const st = "ac48cfbc-668e-4d83-8d5f-828dcd9bd0f3"

export class StreetPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            street: '',
            suggestions: [],
            city: props.city || "35305 Grünberg",
        };
    }

    onStreetSelect = (e) => {
        this.props.onStreetSelect(e)
    }

    handleStreetChange = (event) => {
        const street = event.target.value;
        this.setState({ street });

        if (!street) {
            this.setState({ suggestions: [] });
            return;
        }


        let cityText = `, ${this.city || "35305 Grünberg"}`

        let endpoint = `https://api.mapbox.com/search/v1/suggest/${street}?access_token=${token}&session_token=${st}&language=de&country=DE&limit=5&types=street&proximity=8.960341%2C50.591324`

        console.log(endpoint)

        axios.get(endpoint)
            .then(res => {
                const suggestions = res.data?.suggestions || [];
                this.setState({ suggestions });
            })
            .catch(err => {
                console.error(err);
            });
    }

    handlecityChange = (event) => {
        const city = event.target.value;
        this.setState({ city });
    }

    render() {
        return (
            <div>
                <input
                    id="street"
                    type="text"
                    value={this.state.street}
                    onChange={this.handleStreetChange}
                />
                <ul>
                    {this.state.suggestions.map(i => (
                        <button onClick={() => this.onStreetSelect(i)}>
                            <b>{i.feature_name}</b>
                            <small>{i.description}</small>
                        </button>
                    ))}
                </ul>
            </div>
        );
    }
}

export default StreetPicker;
