import React from "react"
import ApplePage from "../../components/pages/ApplePage/ApplePage"
import "./UnavailableInCityPage.scss"

function UnavailableInCityPage({ }) {
    return (
        <ApplePage title={"Nicht verfügbar"} className="UnavailableInCityPage">
            <h3>Deine Kommune macht noch nicht mit!</h3>

            <p>Es tut uns sehr Leid. Deine Kommune macht noch nicht mit.</p>

            <p>Nimm dir bitte 2 Minuten zeit und hilf uns, deine Kommune zu überzeugen! Jeder Anruf hilft uns!</p>
        </ApplePage>
    )
}
export default UnavailableInCityPage