import React from "react"
import "./NoConnectionPage.scss"

function NoConnectionPage({ }) {
    return (
        <div className="NoConnectionPage">

            <i className="fa-solid fa-triangle-exclamation"></i>
            <h1>Keine Verbindung!</h1>
            <p>Bitte prüfe, ob eine Verbindung zum Internet besteht.</p>

        </div>
    )
}
export default NoConnectionPage