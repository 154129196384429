import { Strapi } from "../../const/api/endpoints"

export default function getSrcFromSingleComponent(singleImageObj) {
    if (!singleImageObj) return null

    let { url, alternativeText, caption, width, height } = singleImageObj?.data?.attributes || {}
    let http = Strapi.MAIN_ADDRESS
    let imgUrl = http + url


    if (!imgUrl) return null

    return imgUrl
}