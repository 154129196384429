import React from "react"
import { useApi } from "../../../service/useApi"

function ItemRender({ endpoint, render, loader = <p>loader...</p>, error = <p>Fehler beim laden.</p>, className }) {
    const { Data, Loading, Error } = useApi(endpoint)

    if (Error) return error
    if (Loading) return loader
    if (Data) {
        return (
            <div className={`ItemRender ${className}`}>
                {render(Data)}
            </div>
        )
    }
}
export default ItemRender