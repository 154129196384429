import React from "react";
import { useLocation } from "react-router-dom";
import ApplePage from "../../components/pages/ApplePage/ApplePage";
import PostItem from "../../components/post/PostItem/PostItem";
import "./PostPage.scss";

function PostPage({ }) {

    let location = useLocation()
    const { postData } = location?.state || {}

    // let { postUrl } = useParams();


    if (!postData) return <p>iugfjiugj</p>

    return (
        <PostPageInner {...postData} />
    )
}
export default PostPage

function PostPageInner(postData) {
    return (
        <ApplePage title="Beitrag">
            <PostItem postData={postData} fullArticle />
        </ApplePage>
    )
}