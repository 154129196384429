import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

function NavigateBackButton({ className, children, asA }) {
    const navigate = useNavigate()
    const location = useLocation();

    function navigateBack() {
        navigate(-1)

        // if (location.state && location.state.prevPath) { //todo geht net
        //     location.history.goBack();
        // } else {
        //     navigate("/home")
        // }
    }

    if (asA) return <a onClick={navigateBack} className={"NavigateBackButton asA " + className}>
        {children}
    </a>
    return (
        <button onClick={navigateBack} className={"NavigateBackButton " + className}>
            {children}
        </button>
    )
}
export default NavigateBackButton