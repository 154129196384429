import React from "react"
import { Link } from "react-router-dom"
import "./EntryItem.scss"

function EntryItem({ entryItem }) {
    let { title, slug, subTitle, website, description, oldMediaJson, openingTimes, type, type2, address, createdAt, keywords, media, contactOptions, subEntries, parentEntry, coordinates } = entryItem || {}
    return (
        <Link to={"/" + slug}>
            <div className="PageInner EntryItem mb-3">
                <RenderImages images={oldMediaJson} />

                <div className="">
                    <div className="d-flex flex-row align-items-baseline flex-wrap">
                        <span className="title">{title}</span>
                    </div>
                    <span className="subtitle">{subTitle}</span>
                </div>
            </div>
        </Link>
    )
}
export default EntryItem

function RenderImages({ images = [] }) {

    return (
        <div className=" RenderImages">
            {images.map((i, k) =>
                <img key={k} className="rounded" src={i.url} />
            )}
        </div>
    )
}