
if (!String.prototype.startsWith) {
  String.prototype.startsWith = function (searchString, position) {
    return this.substr(position || 0, searchString.length) === searchString;
  };
}


export default class AppSettings {
  static set(name, value) {
    this.load();
    this.settings[name] = value;
    this.save();
  }

  static get(name) {
    this.load();
    const value = this.settings[name];
    console.log("value hmm ", value)
    if (value === 'true') {
      return true;
    } else if (value === 'false') {
      return false;
    } else if (value === 'null') {
      return null;
    } else if (value === 'undefined') {
      return undefined;
    } else if (!value) {
      console.log("value hier ")
      return null;
    } else if ((typeof value === 'string' && value instanceof String) && (value.startsWith('{') || value.startsWith('['))) {
      try {
        return JSON.parse(value);
      } catch (error) {
        return value;
      }
    } else {
      console.log("value sonstiges ")

      return value;
    }
  }

  static load() {
    this.settings = JSON.parse(localStorage.getItem('settings')) || {};
  }

  static save() {
    localStorage.setItem('settings', JSON.stringify(this.settings));
    console.log("New Setting saved: ", this.settings)
  }
  static Setting = {
    TRASH_CALENDAR_SAVED: 'TRASH_CALENDAR_SAVED', //Gibt an ob der Nutzer den Abfuhplan-Kalender gespeichert hat, dadurch wird zB ein Banner ausgeblendet 
    TRASH_ASK_IF_CALENDER_SAVED_IS_OKAY: "TRASH_ASK_IF_CALENDER_SAVED_IS_OKAY", //Wenn man den Abfuhplan, runterlädt ladent man normalerweiße in der Kalender-App. Beim nächsten start sol lgefragt werden, ob alles geklappt hat 
  };

}

AppSettings.settings = {};
