import React from "react"
import TileItemLong from "../TileItem/TileItemLong/TileItemLong"
import TileItemModern from "../TileItem/TileItemModern/TileItemModern"
import TileItemSquare from "../TileItem/TileItemSqaure/TileItemSquare"
import "./TileMenu.scss"

function TileMenu(menuData) {
    let {
        title,
        type,
        tileTypes, // NORMAL & LONG
        tiles = [],
    } = menuData

    let heading = <div><h4 className="px-2">{title}</h4></div>


    let renderComponent

    switch (tileTypes) {
        case "LONG":
            renderComponent = TileItemLong
            break;
        case "MODERN":
            renderComponent = TileItemModern
            break;
        case "SQUARE":
        default:
            renderComponent = TileItemSquare
            break;
    }

    return (
        <div className="TileMenu">
            {heading}
            <div className="inner">
                {tiles.map((i, k) => renderComponent({ key: k, ...i }))}
            </div>
        </div>
    )
}
export default TileMenu