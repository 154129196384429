import React from "react"
import { Link } from "react-router-dom"
import "./TileItemLong.scss"

function TileItemLong({ icon, title, page }) {
    return (
        <Link className="TileItemLong" to={{ pathname: page.uri, state: page.payload }}>
            <div className="iconBox">
                <i className={`fa-light fa-${icon}`} />
            </div>
            <span className="title">{title}</span>
        </Link>
    )
}
export default TileItemLong